// refer to https://usefathom.com/support/tracking-advanced

/**
 * Enqueues a command to dispatch to fathom when the library is loaded.
 *
 * @param command - A set of arguments to dispatch to fathom later.
 */
var enqueue = function enqueue(command) {
  window.__fathomClientQueue = window.__fathomClientQueue || [];

  window.__fathomClientQueue.push(command);
};
/**
 * Flushes the command queue.
 */


var flushQueue = function flushQueue() {
  window.__fathomClientQueue = window.__fathomClientQueue || [];

  window.__fathomClientQueue.forEach(function (command) {
    switch (command.type) {
      case 'trackPageview':
        trackPageview(command.opts);
        return;

      case 'trackGoal':
        trackGoal(command.code, command.cents);
        return;

      case 'enableTrackingForMe':
        enableTrackingForMe();
        return;

      case 'blockTrackingForMe':
        blockTrackingForMe();
        return;

      case 'setSite':
        setSite(command.id);
        return;
    }
  });

  window.__fathomClientQueue = [];
};
/**
 * Loops through list of domains and warns if they start with
 * http, https, http://, etc... as this does not work with the
 * Fathom script.
 *
 * @param domains - List of domains to check
 */


var checkDomainsAndWarn = function checkDomainsAndWarn(domains) {
  var regex = /(https?)(?=:|\/|$)/; // matches http or https followed by
  // either a : or /

  domains.forEach(function (domain) {
    if (regex.exec(domain) !== null) console.warn("The include domain ".concat(domain, " might fail to work as intended as it begins with a transfer protocol (http://, https://). Consider removing the protocol portion of the string."));
  });
};

var load = function load(siteId, opts) {
  var tracker = document.createElement('script');
  var firstScript = document.getElementsByTagName('script')[0] || document.querySelector('body');
  tracker.id = 'fathom-script';
  tracker.async = true;
  tracker.setAttribute('data-site', siteId);
  tracker.src = opts && opts.url ? opts.url : 'https://cdn.usefathom.com/script.js';

  if (opts) {
    if (opts.auto !== undefined) tracker.setAttribute('data-auto', "".concat(opts.auto));
    if (opts.honorDNT !== undefined) tracker.setAttribute('data-honor-dnt', "".concat(opts.honorDNT));
    if (opts.canonical !== undefined) tracker.setAttribute('data-canonical', "".concat(opts.canonical));

    if (opts.includedDomains) {
      checkDomainsAndWarn(opts.includedDomains);
      tracker.setAttribute('data-included-domains', opts.includedDomains.join(','));
    }

    if (opts.excludedDomains) {
      checkDomainsAndWarn(opts.excludedDomains);
      tracker.setAttribute('data-excluded-domains', opts.excludedDomains.join(','));
    }

    if (opts.spa) tracker.setAttribute('data-spa', opts.spa);
  }

  tracker.onload = flushQueue;
  firstScript.parentNode.insertBefore(tracker, firstScript);
};
/**
 * Tracks a pageview.
 *
 * @param opts - An optional `url` or `referrer` to override auto-detected values.
 */

var trackPageview = function trackPageview(opts) {
  if (window.fathom) {
    if (opts) {
      window.fathom.trackPageview(opts);
    } else {
      window.fathom.trackPageview();
    }
  } else {
    enqueue({
      type: 'trackPageview',
      opts: opts
    });
  }
};
/**
 * Tracks a goal.
 *
 * @param code - The goal ID.
 * @param cents - The value in cents.
 */

var trackGoal = function trackGoal(code, cents) {
  if (window.fathom) {
    window.fathom.trackGoal(code, cents);
  } else {
    enqueue({
      type: 'trackGoal',
      code: code,
      cents: cents
    });
  }
};
/**
 * Blocks tracking for the current visitor.
 *
 * See https://usefathom.com/docs/features/exclude
 */

var blockTrackingForMe = function blockTrackingForMe() {
  if (window.fathom) {
    window.fathom.blockTrackingForMe();
  } else {
    enqueue({
      type: 'blockTrackingForMe'
    });
  }
};
/**
 * Enables tracking for the current visitor.
 *
 * See https://usefathom.com/docs/features/exclude
 */

var enableTrackingForMe = function enableTrackingForMe() {
  if (window.fathom) {
    window.fathom.enableTrackingForMe();
  } else {
    enqueue({
      type: 'enableTrackingForMe'
    });
  }
};
/**
 * Checks if tracking is enabled for the current vistior
 */

var isTrackingEnabled = function isTrackingEnabled() {
  var preferenceStorage = localStorage.getItem('blockFathomTracking');
  return preferenceStorage !== null ? preferenceStorage !== 'true' : true;
};
/**
 * Sets the Site ID.
 *
 * @param id - The new site ID.
 */

var setSite = function setSite(id) {
  if (window.fathom) {
    window.fathom.setSite(id);
  } else {
    enqueue({
      type: 'setSite',
      id: id
    });
  }
};

export { blockTrackingForMe, enableTrackingForMe, isTrackingEnabled, load, setSite, trackGoal, trackPageview };
